.icon {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        width: 100%;
        height: 100%;
    }
}

.button {
    background-color: rgba(255, 255, 255, 0.5);
    opacity: 0.8;
    position: fixed;
    right: 40px;
    bottom: 40px;
    width: 40px;
    height: 40px;
    border: none;
    cursor: pointer;
    &:hover {
        opacity: 1;
    }
}
