.wrapper {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    position: relative;
}

.input {
    font-family: "Roboto Condensed", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 16px;
    position: relative;
    z-index: 98;
    box-sizing: border-box;
    height: 34px;
    background: rgba(19, 22, 22, 0.5);
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.15);
    border-radius: 2px;
    color: #ddd;
    padding: 0 65px 0 10px;
    backdrop-filter: blur(8px);
}

.suggestions {
    position: absolute;
    z-index: 100;
    top: 34px;
    left: 0;
    right: 0;
    backdrop-filter: blur(8px);
    background: rgba(19, 22, 22, 0.5);
    border: 1px solid rgba(255, 255, 255, 0.15);
    border-top: none;
    overflow-x: hidden;
}

.suggestionsOverlay {
    position: fixed;
    z-index: 97;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.suggestion {
    position: relative;
    min-height: 34px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    opacity: 0.8;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        transition: opacity 0.05s ease-in-out;
        background: linear-gradient(
            180deg,
            rgba(0, 255, 194, 0.2) 0%,
            rgba(52, 239, 194, 0.1) 34.04%,
            rgba(0, 255, 194, 0.1) 61.46%,
            rgba(29, 246, 194, 0.2) 99.98%,
            rgba(11, 253, 195, 0.3) 99.99%
        );
    }

    &:hover {
        opacity: 0.9;
        &:before {
            opacity: 0.6;
        }
    }
}

.suggestionSelected {
    opacity: 1;
    &:before {
        opacity: 1;
    }
}

.clearButton {
    z-index: 99;
    position: absolute;
    background-image: url("./images/autocomplete-clear.png");
    background-repeat: no-repeat;
    background-position: 50%;
    top: 0;
    width: 34px;
    height: 34px;
    right: -34px;
    cursor: pointer;
    opacity: 0.9;
    &:hover {
        opacity: 1;
    }
}
.enterButton {
    z-index: 99;
    position: absolute;
    width: 47px;
    height: 17px;
    top: 9px;
    right: 9px;
    background-repeat: no-repeat;
    background-image: url("./images/autocomplete-enter.png");
    cursor: pointer;
    opacity: 0.9;
    &:hover {
        opacity: 1;
    }
}
