.title {
    font-size: 56px;
    font-weight: 600;
}
.label {
    font-size: 16px;
    color: #9cbaba;
    height: 20px;
}

.cell {
    align-items: center;
    display: flex;
    flex-direction: column;
    @media (max-width: 769px) {
        min-width: 50%;
    }
}

.row {
    @media (max-width: 769px) {
        display: flex;
        flex-direction: revert;
        flex-wrap: wrap;
    }
}
