html {
    height: 100%;

    overflow-x: hidden;
    overflow-y: auto;

    margin-right: -1rem;
    padding-right: 1rem;

    scrollbar-width: none;  // Hide default scrollbar in Firefox
}

:global ::-webkit-scrollbar {
    width: 8px;
}

:global ::-webkit-scrollbar-thumb {
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.3);

    &:hover {
        border-color: rgba(255, 255, 255, 0.2);
        background: rgba(0, 0, 0, 0.5);
    }
}

:global #scrollbar {
    position: fixed;
    right: 0;
    top: 0;
    width: 8px;
    z-index: 1000;
    height: 100vh;
}

:global #thumb {
    position: absolute;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    opacity: 1;
    transition: 0.3s ease-in-out opacity;
    cursor: pointer;
}

:global .thumb-hidden {
    opacity: 0 !important;
    transition: 0.3s ease-in-out opacity;
}