.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    & > div {
        margin: 0 9px 25px;
    }
}

.achievements_row {
}

.header {
    position: relative;
    z-index: 1;
}

.value {
    position: relative;
    display: inline-block;
    color: #a6b9ba;
    font-size: 20px;
    font-weight: 700;
    line-height: 45px;
    vertical-align: top;
}

.pickerRow {
    width: auto !important;
}

.allGroupAchievementsBottomIndent {
    height: 214px;
}