.filterTitle {
    font-weight: bold;
}

.filterHolder {
    position: relative;
    height: 0;
}

.filterHolderInner {
    position: absolute;
    bottom: 0;
    z-index: 99;
    padding: 0.75rem;
    border: 1px solid #172939;
    background-color: rgba(15, 15, 16, 0.95);
    backdrop-filter: blur(5px);
}

.filterActions {
    display: flex;
    flex-direction: row;
    position: relative;
    z-index: 98;
    min-height: 32px;
    align-items: center;
}

.dropFilterWrapper {
    margin-left: 0px;
}

.filterOverlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 97;
}

.link {
    display: inline-flex;
    flex-direction: revert;
    align-items: center;
    position: relative;
    cursor: pointer;
    opacity: 0.8;
    &:hover {
        opacity: 1;
    }

    &:after {
        content: "";
        display: block;
        position: absolute;
        border-bottom: 1px dashed #fff;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.checkboxWithLabelWrapper {
    margin-top: 8px;
    position: relative;
}
.checkboxWithLabelWrapperNarrow {
    display: block;
    div[class^="wru__CheckboxWithLabel__container"] {
        min-width: 65px;
    }
}

.filterItemLabel {
    display: inline-flex;
}

.showFilterButton {
    @extend .link;
    margin-right: 20px;

    &:before {
        content: "";
        display: inline-block;
        width: 12px;
        height: 14px;
        background-image: url("./images/filter-icon.svg");
        background-repeat: no-repeat;
        background-position: 505 50%;
        margin-right: 4px;
    }

    &:after {
        left: 14px;
    }
}

.activeFilters {
    display: flex;
    max-width: 500px;
    overflow: hidden;
    margin-right: 10px;
}

.activeFiltersGroup {
    padding: 2px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 2px;
    box-shadow: 0 0 0 1px transparent, inset 0 -2px 4px 0 transparent;
    background: transparent;
    transition: border 0.25s, box-shadow 0.25s, background 0.25s;
    margin-left: 5px;
    margin-right: 5px;

    &:after {
        content: "";
        display: inline-block;
        background-image: url("./images/filter-clear.png");
        background-repeat: no-repeat;
        background-position: 50%;
        width: 12px;
        height: 12px;
        margin-left: 4px;
        opacity: 0;
    }

    &:hover {
        border-color: rgba(131, 156, 158, 0.6);
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3);
        &:after {
            opacity: 1;
        }
    }
}

.activeFilter {
    padding: 2px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}

.checkboxDisabledWrapper {
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(15, 15, 16, 0.95);
}

.tooltipText {
    margin-top: -2px;
    color: #f93;
}

.filterItemLabel .nationFilter {
    width: 27px;
    height: 27px;
    margin-top: -4px;
}