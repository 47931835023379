.wrapper {
}
.title {
    font-size: 56px;
    font-weight: 600;
}
.label {
    font-size: 16px;
    color: #9cbaba;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px dotted hsla(0, 0%, 53%, 0.41);
    margin-bottom: 3px;
    height: 21px;
}
.rowTitle {
    @extend .row;
    border: none;
    font-weight: 700;
    color: #fff;
    margin-bottom: 12px;
    font-size: 18px;
}

.rowValues {
    display: flex;
    span {
        margin-right: 10px;
        &:last-child {
            margin-right: 0px;
            min-width: 34px;
            text-align: right;
        }
    }
}

.statistisList {
    margin-bottom: 30px;
}

.itemSpacer {
    margin-bottom: 25px;
}

.label {
    color: #ccc;
}

.labelTitle {
    color: #fff;
}
