.wrapper {
    width: 100%;
    height: 100%;
    display: flex;
}

.legend {
    display: flex;
    flex-direction: row;
    width: 120px;
}

.legendColumn {
    display: flex;
    flex-direction: column;
    margin-right: 8px;
    &:last-child {
        margin-right: 0;
    }
}

.sector {
    transition: all 0.15s ease-in-out;
}

.chartWrapper {
    position: relative;
    min-width: 200px;
    max-width: 200px;
}

.value {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    margin-left: -50px;
    height: 50px;
    margin-top: -25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 34px;
}

.dot {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin-right: 6px;
    transition: transform 0.2s ease-in-out;
}

.legendItem {
    display: flex;
    align-items: center;
    .legendDot {
        @extend .dot;
    }
}

.legendItemActive {
    .legendDot {
        @extend .dot;
        transform: scale(1.2);
    }
}
