.menu {
    position: fixed;
    top: 200px;
    > Nav {
        min-width: 200px;
        width: auto;
    }
    > Nav > a {
        white-space: nowrap;
    }
    @media (max-width: 769px) {
        display: none;
    }
}

.menuSpacer {
    padding: 0 240px;
    padding-top: 37px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    & > div {
        width: 100%;
    }
    @media (max-width: 1600px) {
        padding-right: 40px;
    }

    @media (max-width: 769px) {
        padding: 0 20px;
    }
}
