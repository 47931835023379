.wrapper {
    display: flex;
    width: 80px;
    height: 80px;
    & > div {
        width: 100%;
        height: 100%;
    }
}

.receivedDivTooltip {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon {
    width: 80px;
    height: 80px;
    background-position: 50%;
    background-repeat: no-repeat;
    position: relative;
}

.count {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 28px;
    font-size: 25px;
    color: #fff;
    text-shadow: 0 0 10px #000;
}

.groupRow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    & > div {
        margin: 0 9px 25px;
    }
}

.achievementGroupSpoilerTitle {
    font-size: 24px;
    letter-spacing: 0.02em;
}
