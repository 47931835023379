//
// Base fonts
//

// Roboto Condensed
// Font weight:
//    400 - regular
//    700 - bold

@font-face {
    font-family: 'Roboto Condensed';
    font-weight: 700;
    font-style: normal;
    src: local('Roboto Condensed Bold'), local('RobotoCondensed-Bold'), url('../assets/fonts/RobotoCondensed-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto Condensed';
    font-weight: 400;
    font-style: normal;
    src: local('Roboto Condensed Regular'), local('RobotoCondensed-Regular'), url('../assets/fonts/RobotoCondensed-Regular.ttf') format('truetype');
}