.wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content {
    display: flex;
    flex-direction: column;
    width: 50%;
    max-width: 500px;
    min-width: 300px;
}
.search {
    margin-bottom: 40px;
}
