.wrapper {
    width: 100%;
    height: 100%;
}

.bar {
    transition: all 0.15s ease-in-out;
    stroke-width: 0px;
}

.barResize {
    transition: none;
}

.barActive {
    stroke-width: 2px;
}

.text {
    transition: transform 0.15s ease-in-out;
}