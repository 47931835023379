.wrapper {
    margin-top: 22px;
}
.title {
    font-size: 34px;
    margin-bottom: 11px;
}

.withIcon {
    display: flex;
    &:before {
        content: "";
        display: block;
        width: 20px;
        height: 20px;
        margin-right: 4px;
    }
}

.winIcon {
    @extend .withIcon;
    &:before {
        background-image: url("./images/win-icon.png");
    }
}

.battleIcon {
    @extend .withIcon;
    &:before {
        background-image: url("./images/battle-icon.png");
    }
}

.loadMoreButtonWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}


:global {
    .header-cell-number,
    .cell-number {
        min-width: 60px;
    }
}
