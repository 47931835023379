.wrapper {
    min-height: 230px;

    &:before {
        content: " ";
        position: absolute;
        top: -5px;
        bottom: 0;
        left: -3000px;
        right: -3000px;
        opacity: 0;
        z-index: -1;
        pointer-events: none;
        background-image: url("../Background/images/bg.jpg");
        background-attachment: fixed;
        background-position: 50% 0;
        background-size: cover;
        display: none;
    }
    &:after {
        content: " ";
        position: absolute;
        background-image: url("./images/bottom-shadow.png");
        height: 10px;
        left: 0;
        right: 0;
        bottom: -10px;
        opacity: 0;
        display: none;
        z-index: -2;
        pointer-events: none;
    }
}
.stickyWrapper {
    min-height: 80px;
    padding-top: 0.75rem;
    &:before {
        opacity: 1;
        display: block;
    }
}
.stickyWrapperShadow {
    &:after {
        opacity: 0.2;
        display: block;
    }
}
.rowWrapper {
    // width: 100%;
}
.dogtag {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 190px;
}
.dogtagSmall {
    min-height: 80px;
}
.image {
    width: 80px;
    height: 80px;
}
.nameWrapper {
    display: flex;
    flex-direction: column;
}
.name {
    font-size: 30px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 40px;
    line-height: 40px;
}
.registration {
    color: #fff;
}
.clanData {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
}

.searchWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.searchWrapperHeight {
    // height: 60px;
}
