.item {
    position: relative;
    min-height: 34px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    opacity: 0.8;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        transition: opacity 0.05s ease-in-out;
        background: linear-gradient(
            180deg,
            rgba(0, 255, 194, 0.2) 0%,
            rgba(52, 239, 194, 0.1) 34.04%,
            rgba(0, 255, 194, 0.1) 61.46%,
            rgba(29, 246, 194, 0.2) 99.98%,
            rgba(11, 253, 195, 0.3) 99.99%
        );
    }

    &:hover {
        opacity: 0.9;
        &:before {
            opacity: 0.6;
        }
    }
}

.itemSelected {
    opacity: 1 !important;
    &:before {
        opacity: 1 !important;
    }
}
