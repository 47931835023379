.table {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.basicRow {
    display: flex;
    flex-direction: row;
    height: 53px;
    border-top: 1px solid hsla(0, 0%, 100%, 0.08);
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.04);
    box-shadow: 0 -1px rgba(0, 0, 0, 0.2);
}

.rowHighlight {
    border-top: 1px solid rgba(0, 255, 204, 0.2);
    border-bottom: 1px solid rgba(0, 255, 204, 0.2);
    background: linear-gradient(rgba(0, 255, 204, 0.05), transparent, rgba(0, 255, 204, 0.05));
}

.row {
    @extend .basicRow;
    &:hover {
        @extend .rowHighlight;
    }
}

.rowPlaceholder {
    min-height: 51px;
}

.rowExpanded {
    @extend .rowHighlight;
}

.cell {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 12px;
}

.hideOnMobile {
    @media (max-width: 769px) {
        display: none;
    }
}

.header {
    @extend .basicRow;
    background-color: rgba(0, 0, 0, 0.1);
    position: relative;
    &:before {
        content: " ";
        position: absolute;
        top: -5px;
        bottom: 0;
        left: -3000px;
        right: -3000px;
        opacity: 0;
        display: none;
        z-index: -1;
        pointer-events: none;
        background-image: url("../Background/images/bg.jpg");
        background-attachment: fixed;
        background-position: 50% 0;
        background-size: cover;
    }
    &:after {
        content: " ";
        position: absolute;
        background-image: url("./images/bottom-shadow.png");
        height: 10px;
        left: 0;
        right: 0;
        bottom: -10px;
        opacity: 0;
        display: none;
        z-index: -2;
        pointer-events: none;
    }
}

.stickyHeader {
    background-color: rgba(0, 0, 0, 0);
    &:before {
        opacity: 1;
        display: block;
    }
    &:after {
        opacity: 0.2;
        display: block;
    }
}

.headerCell {
    @extend .cell;
    pointer-events: all;
    position: relative;
    &:hover {
        background: hsla(0, 0%, 100%, 0.08);
    }
}

.headerCellActive {
    background: hsla(0, 0%, 100%, 0.08);
}

.headerCell-nation {
    display: flex;
}
.headerCell-class {
    display: flex;
}
.headerCell-level {
    display: flex;
}
.headerCell-name {
    display: flex;
    width: 240px;
}
.headerCell-battles_count {
    display: flex;
}
.headerCell-wins {
    display: flex;
}
.headerCell-premium_exp {
    display: flex;
}

.expand {
    position: relative;
    overflow: hidden;
    transition: height 0.2s linear;
    will-change: height;
}

.expandInner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: linear-gradient(rgba(0, 255, 204, 0.05), transparent, rgba(0, 255, 204, 0.05));
    padding: 0 8px;
}

.arrow {
    display: block;
    width: 14px;
    height: 14px;
    background-image: url("./images/arrow-down.png");
    background-repeat: no-repeat;
    background-position: 50%;
    transform: rotate(0deg);
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 4px;
}
.arrowActive {
    opacity: 1;
    pointer-events: all;
}
.arrowUp {
    transform: rotate(180deg);
}

.notFoundBlock {
    height: 200px;
    padding: 50px 0 50px;
    text-align: center;
    font-size: 28px;
}