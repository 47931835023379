.value {
    position: relative;
    display: inline-block;
    padding: 0 0 0 9px; 
    color: #a6b9ba;
    font-size: 20px;
    font-weight: 700;
    line-height: 45px;
    vertical-align: top;
    // min-width: 220px;
}


.item {
    display: block;
}