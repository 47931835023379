.value {
    position: relative;
    display: inline-block;
    padding: 0 0 0 49px;
    color: #a6b9ba;
    font-size: 20px;
    font-weight: 700;
    line-height: 45px;
    vertical-align: top;
    // min-width: 220px;
}

.battleTypeIcon {
    position: absolute;
    width: 35px;
    height: 35px;
    left: 7px;
    top: 5px;
    background-size: contain;
}

.item {
    display: block;
}