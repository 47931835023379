.wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    min-width: 600px;
}
