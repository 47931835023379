.wrapper {
    position: relative;
}
.toggle {
    position: relative;
    display: inline-block;
    max-width: 100%;
    margin: 1px;
    padding: 0 23px 0 4px;
    border: 1px solid transparent;
    border-radius: 2px;
    box-shadow: 0 0 0 1px transparent, inset 0 -2px 4px 0 transparent;
    background: transparent;
    vertical-align: top;
    transition: border 0.25s, box-shadow 0.25s, background 0.25s;

    &:after {
        content: "";
        background-image: url("./images/arrow-down.png");
        width: 14px;
        height: 14px;
        right: 3px;
        top: 16px;
        position: absolute;
    }

    &:hover {
        border-color: rgba(131, 156, 158, 0.6);
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3);
    }
}

.toggleActive {
    border-color: rgba(131, 156, 158, 0.6);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 0, 0, 0.25);
}

.overlay {
    position: fixed;
    z-index: 97;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.dropPanelWrapper {
    position: relative;
    left: 50%;
    width: 0;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.dropPanel {
    position: absolute;
    // left: 50%;
    opacity: 0;
    z-index: 100;
    backdrop-filter: blur(4px);
    background: rgba(55, 83, 91, 0.9)
        url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAAZCAYAAAAbp/98AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTMyIDc5LjE1OTI4NCwgMjAxNi8wNC8xOS0xMzoxMzo0MCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUuNSAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QUJDODIxRUMyMDUyMTFFNzgwNkRCMUJDMDNDNUZGNUUiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QUJDODIxRUQyMDUyMTFFNzgwNkRCMUJDMDNDNUZGNUUiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpBQkM4MjFFQTIwNTIxMUU3ODA2REIxQkMwM0M1RkY1RSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpBQkM4MjFFQjIwNTIxMUU3ODA2REIxQkMwM0M1RkY1RSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuNs58QAAAAlSURBVHjaYvz//78tAxCwAPF/GOMfEYy/uEVox8BjOw4GQIABAA71G2BI5JXGAAAAAElFTkSuQmCC)
        top repeat-x;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3);
    border: 1px solid transparent;
    border-color: rgba(131, 156, 158, 0.6);
    border-radius: 2px;
    pointer-events: none;
}
.dropPanelVisible {
    opacity: 1;
    pointer-events: all;
}
