.background {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
    background-image: url("./images/bg.jpg");
    background-size: cover;
    background-position: 50% 0;
    background-attachment: fixed;

    & + div {
        min-height: 100vh;
    }
}
