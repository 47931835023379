.wrapper {
  position: relative;
  width: 100%;
}

.title {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  opacity: .87;
  height: 48px;
  font-weight: bold;
  font-size: 24px;
  letter-spacing: 0.02em;

  border-bottom: 1px solid hsla(0,0%,100%,.15);

  &:before {
    content: "";
    margin-right: 10px;
    width: 9px;
    height: 15px;
    background-image: url(./images/triangle.png);
    transition: all .25s ease-in-out;
    will-change: transform;
  }

  &:hover,
  &.active {
    opacity: 1;
  }

  &.active:before {
    transform: rotate(90deg);
  }
}

.content {
  transition: all .25s ease-in-out;
  max-height: 0;
  overflow: hidden;
  margin-left: 19px;

  &.active {
    max-height: 2500px;
    margin-top: 55px;
    margin-bottom: 65px;
  }
}