.wrapper {
    display: flex;
    width: 80px;
    height: 80px;
    max-width: 380px;
    & > div {
        width: 100%;
        height: 100%;
    }
}

.receivedDivTooltip {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 380px;
}

.notReceivedDivTooltip {
    @extend .receivedDivTooltip;
    opacity: 0.5;
}

.icon {
    width: 80px;
    height: 80px;
    background-position: 50%;
    background-repeat: no-repeat;
    position: relative;
}

.tooltipIcon {
    width: 80px;
    height: 80px;
    position: relative;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    flex: 0 0 auto;
}

.count {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 28px;
    color: #fff;
    text-shadow: 0 0 10px #000;
    font-size: 22px;
    font-weight: 700;
    padding-top: 5px;
}

.tooltipTitleTextNotIsReceived {
    overflow: hidden;
    color: rgba(#FFF, 0.9);
    font-weight: 700;
    line-height: 20px;
    text-overflow: ellipsis;
    margin-left: 10px;
    margin-top: 15px;
}

.tooltipTitleTextIsReceived {
    @extend .tooltipTitleTextNotIsReceived;
    color: #FFCC66;
}

.tooltipTitleAchievementTypeIsReceived {
    @extend .tooltipTitleTextIsReceived;
    margin-top: 5px;
    font-weight: 600;
    font-size: 16px;
}

.tooltipTitleAchievementTypeNotReceived {
    @extend .tooltipTitleTextNotIsReceived;
    margin-top: 5px;
    font-weight: 600;
    font-size: 16px;
}

.divTooltipTopMargin {
    margin-top: 3px;
}

.divTooltipBattleRestriction {
    margin-top: 10px;
}

.divTooltipReceivingRestriction {
    margin-top: 10px;
}

.tooltipVehicle {
    display: flex;
    align-items: center;
}

.tooltipBodyItemHr {
    right: 0;
    bottom: 0;
    left: 0;
    border-top: 1px solid rgba(0, 0, 0, .8);
    border-bottom: 1px solid rgba(255, 255, 255, .35);
    opacity: .3;
    content: '';
    margin: 10px -13px;
}

.tooltipProgressBar {
    margin-top: 5px;
}

.row {
    flex: 0 1 auto;
}

.descStyle {
}
