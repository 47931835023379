.wrapper {
    position: relative;
    min-width: 190px;
    min-height: 190px;
    max-width: 190px;
    max-height: 190px;
}

.component {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}

.colorizable {
    -webkit-mask-image: var(--image);
    -webkit-mask-size: contain;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center;
    img {
        mix-blend-mode: overlay;
    }
}

.colorizable.background {
    img {
        filter: brightness(0.7);
    }
}

.clantag {
    position: absolute;
    bottom: 63px;
    font-size: 30px;
    left: 0;
    right: 0;
    text-align: center;
    font-weight: bold;
    height: 30px;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-size: cover;
    background-image: url("./images/whiteBg.png");
}

.clantagBottom {
    bottom: 21px;
    font-size: 22px;
}

.wrapperSmall {
    min-width: 80px;
    min-height: 80px;
    max-width: 80px;
    max-height: 80px;

    .clantag {
        bottom: 32px;
        font-size: 10px;
        height: 10px;
    }

    .clantagBottom {
        bottom: 12px;
    }
}
