@import "./fonts";
@import "./constants";
@import "./scroll";

:global {
    * {
        font-family: $fontFamily;
        -webkit-text-size-adjust: 100%;
        -webkit-font-smoothing: antialiased;
        user-select: none !important;
        outline: none !important;
        line-height: inherit;
        box-sizing: border-box;
        color: #fff;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    html, body, #app {
        min-height: 100vh;
    }

    a[class*="Nav__navItemActive"] {
        span {
            color: #000;
        }
    }

    .item {
        border: 2px solid red;
    }

    .item-enter {
        opacity: 0;
    }
    .item-enter-active {
        opacity: 1;
        transition: opacity 500ms ease-in;
    }
    .item-exit {
        opacity: 1;
    }
    .item-exit-active {
        opacity: 0;
        transition: opacity 500ms ease-in;
    }

    #common_menu {
        position: fixed !important;
        left: 0;
        right: 0;
        top: 0;
    }

    div[class^="wru__Button__inner__"] {
        color: inherit;
        * {
            color: inherit;
        }
    }

    #wows-react-tooltip-body {
        max-width: 400px;
    }

    .we-vehicle__nation-icon {
        width: 27px!important;
        height: 27px!important;
    }

    div[class^="wru__NotificationTemplate__wrapper"] {
        background-color: rgba(0,0,0,0.4);
        backdrop-filter: blur(35px);
    }
}
