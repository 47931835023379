.headerWrapper {
    min-height: 214px;
}
.pickerRow {
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    @media (max-width: 769px) {
        flex-direction: column;
        align-items: center;
    }
}

.seasonPicker {
    margin-left: 10px;
}
