.wrapper {
    display: flex;
}

.value {
    * {
        position: relative;
        display: inline-block;
        color: #a6b9ba;
        font-size: 20px;
        font-weight: 700;
        line-height: 45px;
        vertical-align: top;
    }
    // min-width: 260px;
}

.battleTypeIcon {
    position: absolute;
    background-image: url("./images/battle-selector-sprite.png");
    width: 35px;
    height: 35px;
    left: 7px;
    top: 5px;
    background-position: 0px 0px;
}

.item {
    display: flex;
}
