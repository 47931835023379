.privacyIndicator {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #ffffff;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }

  .privacyTitle {
    margin-right: 10px;
  }

  .privacyProfile, .publicProfile, .linkProfile {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 2px;
      right: -26px;
      height: 18px;
      width: 18px;
      background-size: 18px 18px;
    }
  }

  .privacyProfile {
    &:after {
      background-image: url("./image/icon_noeye.svg");
    }
  }

  .publicProfile {
    &:after {
      background-image: url("./image/icon_eye.svg");
    }
  }

  .linkProfile {
    &:after {
      background-image: url("./image/icon_link.svg");
    }
  }
}

.modal {
  [class^="wru__Dialog__close"] {
    left: 75%;
  }
}

.wrapper {
  padding-top: 60px;
  color: #ddd;
  text-shadow: 0 0 1px #08222a;
  width: 500px;
  margin-right: auto;
  margin-left: auto;

  .title {
    color: #eee;
    font-size: 21px;
    font-weight: 700;
    line-height: 60px;
    text-align: center;
    text-transform: uppercase;
  }

  .radioGroup {
    display: flex;
    flex-direction: column;
    padding: 5px 10px;

    label {
      margin: 15px 0;

      div {
        span {
          opacity: 0.8;

          &:hover {
            opacity: 1;
          }
        }

        .active {
          opacity: 1
        }
      }
    }
  }

  .content {
    overflow-y: hidden;
    height: 180px;
    transition: height 0.5s ease-in-out;
  }

  .checkBoxVisible {
    height: 250px;
  }

  .visibleLinkWithClanMemberContent {
    height: 350px;
  }


  .availability {
    padding: 20px 10px;

    [class^="wru__CheckboxWithLabel__checkboxLabel"] {
      margin-left: 17px;
    }
  }

  .link {
    display: flex;
    padding: 5px 0 5px 10px;
    margin-top: 20px;
    position: relative;

    [class^="wru__Input__container"] {
      width: 60%;
    }

    [class^="wru__Input__input"] {
      width: 100%;
      margin-bottom: 1px;
      padding-right: 30px;
      text-overflow: ellipsis;
    }

    [class^="wru__Input__inner"] {
      padding: 0;
      margin-right: 10px;
    }

    .refreshLink {
      background: url("./image/icon-refresh.svg") no-repeat;
      position: absolute;
      top: 12px;
      left: 272px;
      height: 18px;
      width: 18px;
      border: none;
      opacity: 0.8;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }

    .rotation {
      animation-name: spin;
      animation-duration: 1000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }

    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    .copyLink, .external {
      font-family: "Roboto Condensed", Arial, "Helvetica Neue", Helvetica, sans-serif;
      font-size: 16px;
      position: relative;
      height: 32px;
      padding: 4px 5px 4px 10px;
      border: none;
      background: none;
      font-weight: normal;
      cursor: pointer;
      width: 40%;
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 2px;
        left: 2px;
        height: 24px;
        width: 24px;
        background-image: url("./image/icon-copy.svg");
      }
    }

    .external {
      &:after {
        background-image: url("./image/external-link.svg");
      }
    }
  }

  [class^="wru__Button__button"] {
    margin-right: 20px;
  }
}

.tooltip {
  padding: 5px 10px;
}
