.title {
    font-size: 28px;
    margin-bottom: 30px;
    text-align: center;
}

.chart {
    height: 200px;
}

.legendItem {
    display: block;
}