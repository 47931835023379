.wrapper {
    display: flex;
    flex-direction: column;
}

.header {
    padding-top: 30px;
    max-width: 660px;
    margin: 0 auto;
}

.searchRow {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
